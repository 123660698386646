@import "./variables";
$inputHeight: calc(1.75em + 0.75rem + 2px);
.f-input-group {
  width: 100%;
  &.d-flex {
    align-items: center;
  }
  .f-input-label {
    margin-bottom: 0.25rem;
    display: block;
    font-weight: 600;
  }
  .f-input-label-whiteLabeled {
    margin-bottom: 14px;
    font-weight: 400;
    font-size: 16px;
  }
  .f-input-control {
    width: 100%;
    height: $inputHeight;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    font-size: 1rem;
    font-weight: 400;
    color: #ffffff;
    border: 0;
    outline: 0;
    background: none;
    background-clip: padding-box;
    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: #fff !important;
    }
  }
  .f-input-control-whiteLabeled {
    line-height: 16px;
    font-size: 14px;
    font-weight: 400;
    &::placeholder {
      color: #fff;
      opacity: 0.5;
    }
  }
  .f-input-group-append,
  .f-input-group-prepend {
    ~ .f-input-control {
      padding: 0.375rem 0;
    }
  }

  .f-input-wrap {
    display: flex;
    // background: white;
    border: 1px solid #ffffff;
    border-radius: $borderRadiusBase;
    .f-input-group-prepend,
    .f-input-group-append {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: max-content;
      // width: 40px;
      svg,
      img {
        color: rgba($themePrimaryColor, 1);
        font-size: 1.5rem;
        min-width: 40px;
        width: 25px;
      }

      &.has-action {
        cursor: pointer;
      }
    }

    &:focus-within {
      outline: 0;
      box-shadow: 0 0 2px 1px $themePrimaryColor;
      ~ label {
        color: #14acac;
      }
    }
  }
  &.input-lg {
    .f-form-control {
      width: 100%;
      height: calc(2em + 0.75rem + 2px);
      padding: 0.5rem 0.75rem;
      line-height: 1.5;
      font-size: 1rem;
    }
    .f-input-group-prepend,
    .f-input-group-append {
      padding: 0.5rem 0.75rem;
    }
  }
  &.input-sm {
    .f-form-control {
      height: calc(1em + 0.75rem + 2px) !important;
      padding: 0.25rem 0.5rem;
      line-height: 1rem;
    }
    .f-input-group-prepend,
    .f-input-group-append {
      padding: 0.25rem 0.5rem;
    }
  }
}

// SELECT
.f-input-select {
  .f-select {
    width: 100%;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    background: none !important;
    .f-select__control {
      border-radius: 0;
      border: 0;
      height: auto;
      background: none;
      box-shadow: none;
      &:focus-within {
        border: 0;
        outline: 0;
        box-shadow: none;
      }
    }
    .f-select__placeholder {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: absolute;
      left: 0.5rem;
      top: 0.65rem;
      color: #ffffff;
    }
    .f-select__value-container {
      padding: 0;
      display: flex;
      flex-wrap: nowrap;
      height: $inputHeight;
      padding: 0.375rem 0.75rem !important;
      .f-select__single-value {
        color: #ffffff;
      }
      .f-select__input-container {
        height: $inputHeight;
        min-width: auto !important;
        width: auto !important;
      }
    }
    .f-select__indicators {
      padding-right: 0;
      .f-select__indicator-separator {
        display: none !important;
      }
      .f-select__dropdown-indicator {
        background: url("./img/f-icons/icon-arrow-light.svg") center center no-repeat;
        background-size: 15px;
        width: 40px;
        height: 10px;
        svg {
          display: none;
        }
      }
    }
    .f-select__menu {
      position: absolute;
      top: $inputHeight !important;
      .f-select__menu-list {
        padding: 1rem;
        .f-select__option {
          background: #f6f6f6;
          margin-bottom: 0.5rem;
          border: 0;
          color: rgba($themePrimaryColor, 1);
          text-align: left !important;
          padding: 0.5rem 1rem;
          width: 100%;
          &.f-select__option--is-selected,
          &.f-select__option--is-focused {
            background: rgba($themePrimaryColor, 1);
            color: white;
            // &:after {
            //   content: "";
            //   background: url(../images/icon-check.svg) right center no-repeat;
            //   background-size: 100%;
            //   float: right;
            //   width: 20px;
            //   height: 20px;
            // }
          }
        }
      }
    }
  }
}

.f-input-whiteLabeled-select {
  .f-select {
    width: 100%;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    background: none !important;
    .f-select__control {
      border-radius: 0;
      border: 0;
      height: auto;
      background: none;
      box-shadow: none;
      &:focus-within {
        border: 0;
        outline: 0;
        box-shadow: none;
      }
    }
    .f-select__placeholder {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: absolute;
      left: 0.5rem;
      top: 0.65rem;
      color: #ffffff;
      opacity: 0.5;
      font-size: 14;
      font-weight: 400;
    }
    .f-select__value-container {
      padding: 0;
      display: flex;
      flex-wrap: nowrap;
      height: $inputHeight;
      padding: 0.375rem 0.75rem !important;
      .f-select__single-value {
        color: #ffffff;
      }
      .f-select__input-container {
        height: $inputHeight;
        min-width: auto !important;
        width: auto !important;
      }
    }
    .f-select__indicators {
      padding-right: 0;
      .f-select__indicator-separator {
        display: none !important;
      }
      .f-select__dropdown-indicator {
        background: url("./img/f-icons/icon-arrow-light.svg") center center no-repeat;
        background-size: 15px;
        width: 40px;
        height: 10px;
        svg {
          display: none;
        }
      }
    }
    .f-select__menu {
      position: absolute;
      top: $inputHeight !important;
      .f-select__menu-list {
        padding: 1rem;
        .f-select__option {
          background: red;
          margin-bottom: 0.5rem;
          border: 0;
          color: rgba($themePrimaryColor, 1);
          text-align: left !important;
          padding: 0.5rem 1rem;
          width: 100%;
          &.f-select__option--is-selected,
          &.f-select__option--is-focused {
            background: rgba($themePrimaryColor, 1);
            color: white;
            // &:after {
            //   content: "";
            //   background: url(../images/icon-check.svg) right center no-repeat;
            //   background-size: 100%;
            //   float: right;
            //   width: 20px;
            //   height: 20px;
            // }
          }
        }
      }
    }
  }
}

// DATEPICKER
.f-input-datepicker {
  .react-datepicker {
    .f-input-control {
      padding: 0.375rem 0.85rem;
    }
    .react-datepicker__triangle {
      left: 0;
      transform: translateX(2rem) !important;
    }
  }
}

// RADIO - CHECK
$cr-size: 20px;
$checkbox-bg-color-checked: $themePrimaryColor;
$checkbox-border-color-checked: $checkbox-bg-color-checked;
$checkbox-bg-color-unchecked: #fff;
$checkbox-border-color-unchecked: #757575;
$radio-checked-color: $checkbox-bg-color-checked;
$radio-unchecked-color: $checkbox-border-color-unchecked;

$checkbox-whiteLabeled-bg-color-unchecked: #1b1b1b;
$checkbox-whiteLabeled-bg-color-checked: #1b1b1b;

.f-input-checkbox-group,
.f-input-radio-group {
  &:last-child {
    margin-bottom: 0;
  }

  &.f-input-inline {
    margin-right: 1rem;
    margin-bottom: 0;
    display: inline-block;
  }
  label {
    padding-left: 30px;
  }
}
.f-input-checkbox {
  input[type="checkbox"] {
    display: none;
    @mixin checkbox-structure($width: $cr-size, $height: $cr-size, $top: -2px, $left: "", $border-color: "", $background-color: "") {
      content: "";
      position: absolute;
      width: $width;
      height: $height;
      top: $top;
      border-radius: 2px;
      @if $border-color!= "" {
        border: 2px solid $border-color;
      }

      @if $background-color!= "" {
        background-color: $background-color;
      }
      transition: 0.2s;
    }
    &:not(:checked) + label::before {
      @include checkbox-structure($border-color: $checkbox-border-color-unchecked, $background-color: $checkbox-bg-color-unchecked);
      left: 0;
    }
    &:checked + label {
      &::before {
        @include checkbox-structure($border-color: $checkbox-border-color-checked, $background-color: $checkbox-bg-color-checked);
        left: 0;
      }
      &::after {
        @include checkbox-structure($width: 9px, $height: 14px);
        left: 5px;
        border-top: 2px solid transparent;
        border-left: 2px solid transparent;
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
        -webkit-transform: rotate(36deg);
        -ms-transform: rotate(36deg);
        transform: rotate(36deg);
        animation: fade-in 0.5s;
      }
    }
  }
  label {
    cursor: pointer;
    position: relative;
  }
}
.f-input-purple-checkbox {
  input[type="checkbox"] {
    display: none;
    @mixin checkbox-structure($width: $cr-size, $height: $cr-size, $top: -2px, $left: "", $border-color: "", $background-color: "") {
      content: "";
      position: absolute;
      width: $width;
      height: $height;
      top: $top;
      border-radius: 2px;
      @if $border-color!= "" {
        border: 2px solid #fff;
      }

      @if $background-color!= "" {
        background-color: $background-color;
      }
      transition: 0.2s;
    }
    &:not(:checked) + label::before {
      @include checkbox-structure($border-color: $checkbox-border-color-unchecked, $background-color: transparent);
      left: 0;
    }
    &:checked + label {
      &::before {
        @include checkbox-structure($border-color: $checkbox-border-color-checked, $background-color: transparent);
        left: 0;
      }
      &::after {
        @include checkbox-structure($width: 9px, $height: 14px);
        left: 5px;
        border-top: 2px solid transparent;
        border-left: 2px solid transparent;
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
        -webkit-transform: rotate(36deg);
        -ms-transform: rotate(36deg);
        transform: rotate(36deg);
        animation: fade-in 0.5s;
      }
    }
  }
  label {
    cursor: pointer;
    position: relative;
  }
}
.f-input-radio {
  input[type="radio"] {
    display: none;
    @mixin radio-structure($color) {
      content: "";
      position: absolute;
      width: $cr-size;
      height: $cr-size;
      left: 0;
      top: -2px;
      border-radius: 50%;
      border: 2px solid $color;
    }

    &:not(:checked) + label::before {
      @include radio-structure($radio-unchecked-color);
    }

    &:checked + label {
      &::before {
        @include radio-structure($radio-checked-color);
      }

      &::after {
        @include radio-structure($radio-checked-color);

        background-color: $radio-checked-color;
        -webkit-transform: scale(0.4);
        -ms-transform: scale(0.4);
        transform: scale(0.4);
        animation: fade-in 0.5s;
      }
    }
  }
  label {
    cursor: pointer;
    position: relative;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.input-validation-error {
  color: red;
  margin-top: 0.25rem;
  font-size: 0.9rem;
}
